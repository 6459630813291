<script setup>
import { experiences } from '@/assets/data/experience';

const mapTechToStyle = {
  stack: "bg-green-700",
  dataStructures: "bg-blue-700",
  designPatterns: "bg-yellow-700",
}
</script>

<template>
  <div 
    data-main-container class="flex flex-col gap-3 md:flex-row flex-wrap bg-[#283D3B] overflow-y-auto p-3 justify-center items-center min-h-screen font-mono">

    <div class="tech-animation flex flex-col md:flex-row items-center justify-center w-full text-[#edddd4] gap-5 md:text-2xl">
      <small class="flex flex-row">technology stack / 
        <div class="ml-2 w-[20px] h-[20px] md:w-[30px] md:h-[30px] border inline-block rounded-full bg-green-700"></div>
      </small>
      <small class="flex flex-row">data structures / 
        <div class="ml-2 w-[20px] h-[20px] md:w-[30px] md:h-[30px] border inline-block rounded-full bg-blue-700"></div>
      </small>
      <small class="flex flex-row">design pattern / 
        <div class="ml-2 w-[20px] h-[20px] md:w-[30px] md:h-[30px] border inline-block rounded-full bg-yellow-700"></div>
      </small>
    </div>

    <div 
      v-for="experience in experiences" 
      :key="experience.id" 
      class="experience-card h-[500px] md:h-[700px] w-[350px] md:w-[400px] bg-[#edddd4] rounded-md shadow-2xl p-2">
      
      <div class="flex flex-col h-full text-center">
        <h2 v-html="experience.company" class="text-xl font-semibold hover:text-blue-600 transition-colors"></h2>
        <small class="text-[0.5em] md:text-[0.75em]" v-html="experience.role"/>
        <small class="text-[0.5em] md:text-[0.75em] italic bold" v-html="experience.duration"/>
        
        <!-- Loop through technologies -->
        <div class="flex flex-wrap gap-1 mt-4">
          <small 
            v-for="(tech, index) in [
              ...(experience.tech.stack || []), 
              ...(experience.tech.dataStructures || []), 
              ...(experience.tech.designPatterns || [])
            ]" 
            :key="index" 
            class="p-1 rounded mx-auto text-[0.5em] md:text-[0.7em] text-white"
            :class="[
              experience.tech.stack.includes(tech) ? mapTechToStyle.stack : '',
              experience.tech.dataStructures.includes(tech) ? mapTechToStyle.dataStructures : '',
              experience.tech.designPatterns.includes(tech) ? mapTechToStyle.designPatterns : ''
            ]"
          >
            {{ tech }}
          </small>
        </div>
        
        <p class="text-left text-[0.6em] md:text-[1em] leading-4 mt-8 px-3" v-html="experience.description"/>
      </div>
    </div>
  </div>
</template>


<style>
.tech-animation {
  opacity: 0;
  animation: fadeInTech 1s ease-in-out forwards;
}

.experience-card {
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
  transition: transform 0.3s, box-shadow 0.3s;
}

@keyframes fadeInTech {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
